<template>
  	<div id="coach">

      <div class="ql-container ql-snow">

          <div class="ql-editor">
            <div id="rich-text" class="rich-text" v-html="richText"></div>
          </div>
      </div>
      <div class="time-line" v-if="timeline.length>0">
        <h1 style="font-weight: 800;color: #000000;">历程回顾</h1>
        <div class="content">
          <img :src="timeline[curIndex].image" alt="">
          <div class="right" v-html="timeline[curIndex].intro"></div>
        </div>
        <ul class="timeline" id="timeline" v-if="timeline.length>0">
          <li class="li"  v-for="(item,index) in timeline" :key="index">
            <div class="timestamp">
              <!-- <span class="author">Abhi Sharma</span>
              <span class="date">11/15/2014</span> -->
            </div>
            <div :class="index === curIndex ? 'status-active' : 'status'" @click="curIndex = index">
              <h4 :style="{color: index === curIndex ? '#000' : ''}"> {{item.year==undefined?'':item.year.slice(0,4)}} </h4>
            </div>
          </li>
         </ul>
      </div>
      <div class="imgs">
        <h1>精选照片</h1>
        <el-carousel :interval="5000" arrow="always" height="50vw" indicator-position="outside">
            <el-carousel-item v-for="item in honorImage" :key="item">
              <el-image :src="item" fit="cover"></el-image>
            </el-carousel-item>
        </el-carousel>
      </div>
      <div class="videos">
        <h1>视频集锦</h1>
        <el-carousel :interval="5000" arrow="always" height="300px" type="card" :autoplay="false">
            <el-carousel-item v-for="item in video" :key="item">
              <video :src="item" controls></video>
            </el-carousel-item>
        </el-carousel>
      </div>
  	</div>
</template>

<script>
  export default{
    data(){
      return{
        coachData:{},
        richText:'',
        personalImage:'',
        honorImage: [],
        video: [],
        curIndex: 0,
        timeline:[
          {
            time: 2000,
            img: 'https://abc.xnrun.com/image/default/D3AD79BFE033463890237B8623C08188-6-2.png',
            content: ''
          },
          {
            time: 2000,
            img: 'https://abc.xnrun.com/image/default/D3AD79BFE033463890237B8623C08188-6-2.png',
            content: ''
          },
          {
            time: 2000,
            img: 'https://abc.xnrun.com/image/default/D3AD79BFE033463890237B8623C08188-6-2.png',
            content: ''
          },
          {
            time: 2000,
            img: 'https://abc.xnrun.com/image/default/D3AD79BFE033463890237B8623C08188-6-2.png',
            content: ''
          },
          {
            time: 2000,
            img: 'https://abc.xnrun.com/image/default/D3AD79BFE033463890237B8623C08188-6-2.png',
            content: ''
          },
          {
            time: 2000,
            img: 'https://abc.xnrun.com/image/default/D3AD79BFE033463890237B8623C08188-6-2.png',
            content: ''
          },
        ]
      }
    },
    created() {
    	console.log('rich-text in coach.html');
      this.getData().then(()=>{
        this.getTimeline()
      })
    },
    methods:{
      async getData(){
        let res = await this.$http.get('/official/student/getCoachWalls')
        console.log('啃啃啃',res.data.data)
        this.coachData = res.data.data
        this.richText = res.data.data.intro
        this.richText= this.richText.replace(/<img/g, '<img style="width:100%" ');
        this.richText = this.richText.replace(
          /<iframe class="ql-video" frameborder="0" allowfullscreen="true"/g,
          '<video style="width: 100%;" controls><source ');
        this.richText = this.richText.replace(/<\/iframe>/g, ' type="video/mp4"></video> ');

        this.personalImage = res.data.data.personalImage;

        this.honorImage = res.data.data.honorImage.split(',')
        if(this.honorImage[this.honorImage.length-1] === ''){
        	this.honorImage.pop()
        }

        this.video = res.data.data.video.split(';')
        if(this.video[this.video.length-1] === ''){
        	this.video.pop()
        }
      },
	  async getTimeline(){
		  let res = await this.$http.get('/official/student/queryHistoricalReview',{params:{
			  studentId: this.coachData.uuid
		  }})
      console.log({timeline:res.data.data})
      this.timeline = res.data.data
	  }
    }
  }
</script>

<style scoped="scoped" lang="scss">
  @import url('https://cdn.quilljs.com/1.3.3/quill.snow.css');
  img{
    object-fit: cover;
    object-position: top;
  }
  .rich-text{
  	max-width: 1170px;
  	margin: 0 auto;
  	padding: 100px 0;
  }
  @media screen and (max-width: 1200px) {
    .ql-editor {
      padding:0 !important;
      }

  }
  .el-carousel--horizontal {
      max-width: 1170px;
      margin: 0 auto;

  }
  .el-image{
  	width: 100%;
  	height: 100%;
  }
  ::v-deep .el-image__inner{
  	object-fit: cover;
    object-position: top;
  }
  video{
  	width: 100%;
  	height: 100%;
  	object-fit: cover;
  }
  .time-line{
    background-image: url(https://abc.xnrun.com/image/default/DF446E42653C488FB056AB431F552399-6-2.png);
          background-size:100% 100%;
    // max-width: 1170px;
    padding: 30px 10vw;
    color: #ccc;
    // height: 90vh;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    h1{
      margin-bottom: 50px;
      letter-spacing: 20px;
    }
    .timeline {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .li {
        transition: all 200ms ease-in;
    }
    .timestamp {
        margin-bottom: 20px;
        padding: 0px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 100;
    }
    .status {
        padding: 0px 40px;
        display: flex;
        justify-content: center;
        border-top: 2px solid #D6DCE0;
        position: relative;
        transition: all 200ms ease-in;

        h4{
          margin: 20px 0;
        }
    }
    .status:before {
        content: "";
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 10px;
        position: absolute;
        top: -11px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 200ms ease-in;
        cursor: pointer;
    }
    .status-active {
        padding: 0px 40px;
        display: flex;
        justify-content: center;
        border-top: 2px solid #D6DCE0;
        position: relative;
        transition: all 200ms ease-in;

        h4{
          margin: 20px 0;
        }
    }
    .status-active:before {
        content: "";
        width: 20px;
        height: 20px;
        background-color: #f50;
        border-radius: 10px;
        position: absolute;
        top: -11px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 200ms ease-in;
        cursor: pointer;
    }
    .content{
      display: flex;
      margin-bottom: 30px;

      img{
        width: 666px;
        height: 555px;
      }
      .right{
        word-break: break-all;
        // overflow: hidden;
        padding: 30px;
      }
    }

  }
  h1{
    margin: 50px 0;
  }
</style>
